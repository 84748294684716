import React from 'react';
import withIcon from '../withIcon';

function FavoriteFilled() {
  return (
    <path d="M20.915 11.408c-.63 4.332-4.81 7.797-8.656 9.304-.074.038-.148.038-.259.038-.111 0-.148 0-.259-.038-3.884-1.507-8.064-5.01-8.656-9.304-.296-2.035.185-3.918 1.332-5.274C5.453 4.928 6.895 4.25 8.449 4.25 9.78 4.25 11 4.74 12 5.682c.999-.942 2.22-1.432 3.551-1.432 1.517 0 2.996.678 4.032 1.884 1.147 1.356 1.628 3.24 1.332 5.274z" />
  );
}

export default withIcon(FavoriteFilled);
