import React from 'react';
import withIcon from '../withIcon';

function QuestionSmall() {
  return (
    <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16zM5.998 4.822c.114-.106.24-.207.377-.302a2.813 2.813 0 0 1 .979-.426c.189-.042.393-.064.613-.064.29 0 .558.043.806.127.247.084.46.203.64.357.18.155.321.341.424.561.103.22.154.468.154.743 0 .279-.041.519-.124.72a2.02 2.02 0 0 1-.313.531 2.413 2.413 0 0 1-.415.396c-.15.112-.291.217-.421.317-.13.099-.242.197-.336.296a.575.575 0 0 0-.162.33l-.099.842H7.45l-.066-.913v-.06c0-.155.041-.291.124-.41.082-.12.187-.231.313-.336.127-.104.262-.209.407-.313.145-.105.28-.22.407-.347.127-.126.231-.27.314-.432.082-.16.123-.351.123-.572a.969.969 0 0 0-.346-.756 1.118 1.118 0 0 0-.377-.206 1.478 1.478 0 0 0-.465-.072c-.223 0-.415.028-.574.083a2.155 2.155 0 0 0-.671.363c-.068.055-.124.083-.168.083a.24.24 0 0 1-.215-.127l-.258-.413zm1.078 6.573a.699.699 0 0 1 .195-.49.673.673 0 0 1 .487-.203.706.706 0 0 1 .49.204.699.699 0 0 1 .198.49.676.676 0 0 1-.687.688.699.699 0 0 1-.27-.054.638.638 0 0 1-.218-.146.7.7 0 0 1-.195-.489z" />
  );
}

QuestionSmall.viewBoxSize = '16';

export default withIcon(QuestionSmall);
