import React, { useState } from "react";
import Content from "components/Content/Content.component";
import Container from "components/Container/Container.component";
import Navbar from "components/Navbar";
import Button from "components/Button/Button.component";
import Text from "components/Text/Text.component";

const Account = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error] = useState("");

  const createUser = (event) => {
    event.preventDefault();

    if (firstname && lastname && email && password) {
      /* login({ email: email, password: password }).then((error) => {
        if (error) {
          setError(error);
        } else {
          setError("");
        }
      }); */
    }
  };

  return (
    <>
      <Navbar />
      <Content>
        <Container>
          <form onSubmit={createUser}>
            <label htmlFor="firstname">Förnamn</label>
            <input
              id="firstname"
              placeholder="Förnamn"
              value={firstname}
              onChange={(event) => setFirstname(event.target.value)}
            />
            <label htmlFor="lastname">Efternamn</label>
            <input
              id="lastname"
              placeholder="Efternamn"
              value={lastname}
              onChange={(event) => setLastname(event.target.value)}
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <label htmlFor="password">Lösenord</label>
            <input
              id="password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button color="black-light" size="s" style={{ marginTop: 8 }}>
              Create account
            </Button>
            {error && (
              <Text color="negative" style={{ marginTop: 8 }}>
                {error}
              </Text>
            )}
          </form>
        </Container>
      </Content>
    </>
  );
};

export default Account;
