import React from 'react';
import withIcon from '../withIcon';

function Favorite() {
  return (
    <path d="M19.925 11.264c.256-1.757-.149-3.353-1.1-4.479-.84-.977-2.04-1.535-3.274-1.535-1.077 0-2.052.393-2.865 1.16L12 7.055l-.686-.647C10.5 5.643 9.526 5.25 8.449 5.25c-1.256 0-2.424.546-3.268 1.53-.957 1.13-1.362 2.727-1.105 4.491.48 3.477 3.867 6.873 7.922 8.47 4.071-1.63 7.42-4.987 7.927-8.477zm.99.144c-.63 4.332-4.81 7.797-8.656 9.304-.074.038-.148.038-.259.038-.111 0-.148 0-.259-.038-3.884-1.507-8.064-5.01-8.656-9.304-.296-2.035.185-3.918 1.332-5.274C5.453 4.928 6.895 4.25 8.449 4.25 9.78 4.25 11 4.74 12 5.682c.999-.942 2.22-1.432 3.551-1.432 1.517 0 2.996.678 4.032 1.884 1.147 1.356 1.628 3.24 1.332 5.274z" />
  );
}

export default withIcon(Favorite);
