import React from 'react';
import withIcon from '../withIcon';

function AlarmFilled() {
  return (
    <path d="M7.827 18.145l-2.094 2.564a.5.5 0 1 1-.774-.632l2.051-2.513a7.934 7.934 0 0 1 4.988-14.106 7.934 7.934 0 0 1 5.008 14.09l2.064 2.529a.5.5 0 0 1-.774.632l-2.105-2.578a7.898 7.898 0 0 1-4.193 1.197 7.898 7.898 0 0 1-4.17-1.183zM3.852 7.129a.5.5 0 0 1-.921-.39 7.957 7.957 0 0 1 3.337-3.78.5.5 0 0 1 .501.865A6.957 6.957 0 0 0 3.852 7.13zm17.214-.39a.5.5 0 1 1-.921.39 6.965 6.965 0 0 0-2.918-3.305.5.5 0 0 1 .502-.865 7.965 7.965 0 0 1 3.337 3.78zm-6.375 4.896h-2.63V8.222a.5.5 0 1 0-1 0v4.413h3.63a.5.5 0 1 0 0-1z" />
  );
}

export default withIcon(AlarmFilled);
