import React from 'react';
import withIcon from '../withIcon';

function Edit() {
  return (
    <path d="M19.232 8.06a1.5 1.5 0 0 0 0-2.12l-1.171-1.172a1.5 1.5 0 0 0-2.122 0L14.697 6.01 14 6.707l.697-.697L18 9.293l1.232-1.232zM18 9.294l.707.707-10.14 10.14a1.5 1.5 0 0 1-.766.41l-3.232.646a1.5 1.5 0 0 1-1.765-1.765L3.45 16.2a1.5 1.5 0 0 1 .41-.767L15.233 4.061a2.5 2.5 0 0 1 3.536 0l1.171 1.171a2.5 2.5 0 0 1 0 3.536L18 10.708l.707-.708L18 9.293zm-.707.707L14 6.707 4.568 16.14a.5.5 0 0 0-.137.256l-.647 3.232a.5.5 0 0 0 .589.589l3.232-.647a.5.5 0 0 0 .256-.137L17.293 10z" />
  );
}

export default withIcon(Edit);
