import React from 'react';
import withIcon from '../withIcon';

function Alarm() {
  return (
    <path d="M16.19 18.131a7.898 7.898 0 0 1-4.192 1.197 7.898 7.898 0 0 1-4.17-1.183l-2.095 2.564a.5.5 0 1 1-.774-.632l2.051-2.513a7.934 7.934 0 0 1 4.988-14.106 7.934 7.934 0 0 1 5.008 14.09l2.064 2.529a.5.5 0 0 1-.774.632l-2.105-2.578zm-8.208-1.083a6.903 6.903 0 0 0 4.016 1.28 6.934 6.934 0 1 0 0-13.87 6.934 6.934 0 0 0-4.016 12.59zm-4.13-9.92a.5.5 0 0 1-.921-.39 7.957 7.957 0 0 1 3.337-3.78.5.5 0 0 1 .501.866A6.957 6.957 0 0 0 3.852 7.13zm17.214-.39a.5.5 0 1 1-.921.39 6.965 6.965 0 0 0-2.918-3.304.5.5 0 0 1 .502-.865 7.965 7.965 0 0 1 3.337 3.78zm-6.375 4.897a.5.5 0 1 1 0 1h-3.63V8.222a.5.5 0 1 1 1 0v3.413h2.63z" />
  );
}

export default withIcon(Alarm);
