import React from 'react';
import withIcon from '../withIcon';

function SocialTwitter() {
  return (
    <path d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm0-39C9.507 1 1 9.507 1 20s8.507 19 19 19 19-8.507 19-19S30.493 1 20 1zm8.78 14.502a7.366 7.366 0 0 1-1.795 1.854v.488c0 4.756-3.614 10.244-10.244 10.244a10.244 10.244 0 0 1-5.521-1.62c1.9.22 3.809-.317 5.317-1.492a3.605 3.605 0 0 1-3.366-2.503c.228.043.46.064.692.064.322.001.642-.041.952-.127a3.6 3.6 0 0 1-2.893-3.532v-.044a3.63 3.63 0 0 0 1.634.449 3.61 3.61 0 0 1-1.117-4.81 10.244 10.244 0 0 0 7.43 3.761 3.605 3.605 0 0 1 6.137-3.283 7.17 7.17 0 0 0 2.287-.873 3.6 3.6 0 0 1-1.58 1.99 7.122 7.122 0 0 0 2.067-.566z" />
  );
}

SocialTwitter.viewBoxSize = '40';

export default withIcon(SocialTwitter);
