import React from 'react';
import withIcon from '../withIcon';

function SocialLinkedIn() {
  return (
    <path d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm0-39C9.507 1 1 9.507 1 20s8.507 19 19 19 19-8.507 19-19S30.493 1 20 1zm-4.912 26.273h-3.415V16.307h3.415v10.966zm-1.8-12.336a1.902 1.902 0 0 1-2.068-1.766v-.147c0-1.083.824-1.902 2.092-1.902a1.912 1.912 0 0 1 2.059 1.756v.146c.005 1.074-.8 1.913-2.083 1.913zM28.78 27.273h-3.902V21.6c0-1.463-.605-2.498-1.951-2.498-.843 0-1.59.541-1.854 1.342-.086.29-.12.592-.097.893v5.936h-3.8s.048-10.049 0-10.96h3.844v1.736c.229-.751 1.463-1.825 3.414-1.825 2.44 0 4.346 1.576 4.346 4.971v6.078z" />
  );
}

SocialLinkedIn.viewBoxSize = '40';

export default withIcon(SocialLinkedIn);
