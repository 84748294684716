import React from 'react';
import withIcon from '../withIcon';

function Avatar() {
  return (
    <path d="M5.929 19.307c.735-1.64 1.908-2.953 3.329-3.702-1.627-.909-2.73-2.601-2.747-4.544a5.175 5.175 0 0 1 1.585-3.773 5.558 5.558 0 0 1 3.857-1.576c3.007-.02 5.51 2.33 5.536 5.252a5.175 5.175 0 0 1-1.585 3.774 5.51 5.51 0 0 1-1.16.867c1.42.75 2.592 2.062 3.327 3.702A9.5 9.5 0 0 0 12 2.5a9.5 9.5 0 0 0-6.071 16.807zm1.276.896A9.456 9.456 0 0 0 12 21.5a9.457 9.457 0 0 0 4.788-1.293c-.884-2.217-2.686-3.744-4.77-3.753H12c-2.092 0-3.906 1.528-4.795 3.75zm4.797-12.952h-.036a4.028 4.028 0 0 0-2.795 1.137 3.651 3.651 0 0 0-1.122 2.66c.019 2.063 1.786 3.727 3.948 3.727h.036a4.027 4.027 0 0 0 2.795-1.137 3.65 3.65 0 0 0 1.123-2.66c-.019-2.063-1.786-3.727-3.949-3.727zM1 12C1 5.924 5.925 1 12 1s11 4.924 11 11c0 6.075-4.925 11-11 11S1 18.075 1 12z" />
  );
}

export default withIcon(Avatar);
