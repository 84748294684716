import React from 'react';
import withIcon from '../withIcon';

function SocialInstagram() {
  return (
    <path d="M20,40 C8.954305,40 1.3527075e-15,31.045695 0,20 C-1.3527075e-15,8.954305 8.954305,2.02906125e-15 20,0 C31.045695,-2.02906125e-15 40,8.954305 40,20 C40,31.045695 31.045695,40 20,40 Z M20,1 C9.50658975,1 1,9.50658975 1,20 C1,30.4934102 9.50658975,39 20,39 C30.4934102,39 39,30.4934102 39,20 C39,9.50658975 30.4934102,1 20,1 Z M25.3658537,11.2195122 C26.2714709,11.2195122 27.1399965,11.5792673 27.7803646,12.2196354 C28.4207327,12.8600035 28.7804878,13.7285291 28.7804878,14.6341463 L28.7804878,25.3658537 C28.7804878,26.2714709 28.4207327,27.1399965 27.7803646,27.7803646 C27.1399965,28.4207327 26.2714709,28.7804878 25.3658537,28.7804878 L14.6341463,28.7804878 C13.7285291,28.7804878 12.8600035,28.4207327 12.2196354,27.7803646 C11.5792673,27.1399965 11.2195122,26.2714709 11.2195122,25.3658537 L11.2195122,14.6341463 C11.2195122,12.748296 12.748296,11.2195122 14.6341463,11.2195122 L25.3658537,11.2195122 Z M26.3414634,13.2439024 L23.7609756,13.2439024 L23.7609756,16.2195122 L26.7268293,16.2195122 L26.7268293,13.2439024 L26.3414634,13.2439024 Z M17.497561,18.2 C17.1185219,18.7237763 16.9137457,19.3534633 16.9121951,20 C16.9077742,21.5204124 18.0108016,22.8177779 19.5121234,23.0579993 C21.0134451,23.2982208 22.466213,22.4097995 22.9364191,20.9639158 C23.4066252,19.5180321 22.7543189,17.9450338 21.3988786,17.256227 C20.0434383,16.5674201 18.3882924,16.9678187 17.497561,18.2 Z M27.0731707,25.3658537 L27.0732847,18.2 L24.4487805,18.2 C25.261046,20.204968 24.6318034,22.5055305 22.9121679,23.8179808 C21.1925324,25.1304311 18.8074676,25.1304311 17.0878321,23.8179808 C15.3681966,22.5055305 14.738954,20.204968 15.5512195,18.2 L12.9317073,18.2 L12.9317073,25.4 C12.9355562,25.846762 13.1174087,26.2735439 13.4369702,26.5857802 C13.7565317,26.8980166 14.1874152,27.0699251 14.6341463,27.0634146 L25.404878,27.0634146 C25.8516321,27.0608475 26.2789189,26.8802338 26.5920681,26.5615909 C26.9052172,26.2429479 27.078373,25.8125848 27.0731707,25.3658537 Z" />
  );
}

SocialInstagram.viewBoxSize = '40';

export default withIcon(SocialInstagram);
