import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Button.module.scss";
import { HTMLProps } from "react";

type ButtonProps = {
  as?: any;
  kind?: "border" | "filled" | "ghost";
  size?: "s" | "m";
  color?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  onClick?: any;
  loading?: boolean;
  icon?: any;
  iconPosition?: "left" | "right";
} & Omit<HTMLProps<HTMLButtonElement>, "size">;

function Button({
  as: Tag = "button",
  kind = "filled",
  size = "m",
  color = "primary",
  children,
  disabled,
  fullWidth,
  className,
  onClick,
  loading,
  icon,
  iconPosition = "left",
  ...rest
}: ButtonProps) {
  const classes = classNames(
    styles.button,
    {
      [styles.border]: kind === "border",
      [styles.filled]: kind === "filled",
      [styles.ghost]: kind === "ghost",
      [styles.small]: size === "s",
      [styles.fullWidth]: fullWidth,
      [styles.withIcon]: icon,
      [styles.iconRight]: icon && iconPosition === "right",
      [styles.loading]: loading,
      [styles[color]]: color,
      [styles.disabled]: disabled,
    },
    className
  );

  const iconWithSize = (icon) => {
    return React.cloneElement(icon, { size: size });
  };

  return (
    <Tag
      {...rest}
      className={classes}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        "Loading..."
      ) : (
        <>
          {icon && (
            <div className={styles.iconWrapper}>{iconWithSize(icon)}</div>
          )}
          {children}
        </>
      )}
    </Tag>
  );
}

export default Button;
