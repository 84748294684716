import React from 'react';
import withIcon from '../withIcon';

function Compare() {
  return (
    <path d="M13.868 4.533l4.122-.375.01-.018.44.824c2.711 5.067 4.06 8.334 4.06 9.924a4.5 4.5 0 1 1-9 0c0-1.564 1.305-4.75 3.926-9.675l-3.445.314a2 2 0 0 1-3.88.352l-3.591.327C9.174 11.199 10.5 14.424 10.5 16a4.5 4.5 0 1 1-9 0c0-1.59 1.349-4.856 4.06-9.924L6 5.252l.009.016-.002-.02 4.027-.367a2 2 0 0 1 3.834-.348zm.677 9.857h6.91c-.235-1.46-1.39-4.191-3.455-8.123-2.065 3.932-3.22 6.663-3.455 8.123zm6.92 1h-6.93a3.5 3.5 0 0 0 6.93 0zM2.534 16.5a3.5 3.5 0 0 0 6.93 0h-6.93zm6.92-1C9.217 14.04 8.064 11.31 6 7.38c-2.064 3.93-3.218 6.66-3.454 8.12h6.908zM13 5.25a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
  );
}

export default withIcon(Compare);
