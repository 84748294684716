import React from 'react';
import withIcon from '../withIcon';

function SocialPinterest() {
  return (
    <path d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm0-39C9.507 1 1 9.507 1 20s8.507 19 19 19 19-8.507 19-19S30.493 1 20 1zm0 10.166c.792.004 1.58.118 2.341.336a8.78 8.78 0 0 1-4.814 16.888 7.43 7.43 0 0 0 .883-1.878c.127-.414.614-2.439.614-2.439a2.502 2.502 0 0 0 2.137 1.078c2.815 0 4.727-2.575 4.727-6.02 0-2.59-2.2-5.029-5.547-5.029-4.165 0-6.263 2.996-6.263 5.488 0 1.512.57 2.869 1.795 3.356a.307.307 0 0 0 .44-.22c.043-.15.136-.54.18-.702a.434.434 0 0 0-.127-.487 2.566 2.566 0 0 1-.576-1.722v-.22a4.151 4.151 0 0 1 4.308-3.985c2.341 0 3.643 1.463 3.668 3.375 0 2.532-1.122 4.669-2.78 4.669a1.361 1.361 0 0 1-1.4-1.693c.287-1.112.775-2.312.775-3.117v-.156a1.176 1.176 0 0 0-1.19-1.161c-.937 0-1.688.97-1.688 2.273-.006.478.089.951.278 1.39l-1.127 4.786a7.77 7.77 0 0 0-.151 2.014A8.78 8.78 0 0 1 20 11.166z" />
  );
}

SocialPinterest.viewBoxSize = '40';

export default withIcon(SocialPinterest);
