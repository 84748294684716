import React from 'react';
import withIcon from '../withIcon';

function Search() {
  return (
    <path d="M17.872 16.493l4.844 4.844a1 1 0 0 1-.003 1.408l.032-.032a.999.999 0 0 1-1.408.003l-4.844-4.844A9.46 9.46 0 0 1 10.5 20a9.5 9.5 0 1 1 9.5-9.5 9.46 9.46 0 0 1-2.128 5.993zm-12.675-.69A7.45 7.45 0 0 0 10.5 18c2.003 0 3.887-.78 5.303-2.197A7.45 7.45 0 0 0 18 10.5a7.45 7.45 0 0 0-2.197-5.303A7.448 7.448 0 0 0 10.5 3c-2.003 0-3.887.78-5.303 2.196A7.45 7.45 0 0 0 3 10.5c0 2.003.78 3.887 2.197 5.303z" />
  );
}

export default withIcon(Search);
