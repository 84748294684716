import React from 'react';
import withIcon from '../withIcon';

function ExternalLink() {
  return (
    <path d="M18.663 5.294a.483.483 0 0 1 .053.053.474.474 0 0 1 .118.314V8.59a.5.5 0 0 1-.488.5.476.476 0 0 1-.488-.476V6.819l-5.568 5.568a.476.476 0 0 1-.674-.673l5.572-5.571h-1.8a.476.476 0 0 1-.476-.488.5.5 0 0 1 .5-.488h2.927c.125 0 .24.048.324.127zM16.5 11a.5.5 0 0 1 .5.5V16a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 7 16V8a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 1 1 0 1h-4A.5.5 0 0 0 8 8v8a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-4.5a.5.5 0 0 1 .5-.5z" />
  );
}

export default withIcon(ExternalLink);
