import React, { HTMLProps } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Text.module.scss";

type TextProps = {
  children?: React.ReactNode;
  cursive?: boolean;
  inline?: boolean;
  size?: "xs" | "s" | "m" | "l";
  nowrap?: boolean;
  color?:
    | "text-primary"
    | "text-secondary"
    | "text-disabled"
    | "white"
    | "primary"
    | "positive"
    | "negative";
  bold?: boolean;
  normal?: boolean;
  uppercase?: boolean;
  tight?: boolean;
  weight?: "normal" | "bold";
  className?: string;
} & Omit<HTMLProps<HTMLParagraphElement>, "size">;

const Text = ({
  children,
  cursive,
  inline,
  size,
  nowrap,
  color,
  bold,
  normal,
  uppercase,
  tight,
  weight,
  className,
  ...rest
}: TextProps) => {
  const Tag = inline ? "span" : "p";
  const classes = classNames(styles.text, {
    ...(size && { [styles[size]]: size }),
    ...(color && { [styles[color]]: color }),
    [styles.bold]: bold,
    [styles.normal]: normal,
    [styles.uppercase]: uppercase,
    [styles.cursive]: cursive,
    [styles.nowrap]: nowrap,
    [styles.tight]: tight,
    ...(weight && { [styles[weight]]: weight }),
    ...(className && { [className]: className }),
  });

  return (
    <Tag {...rest} className={classes}>
      {children}
    </Tag>
  );
};

export default Text;
