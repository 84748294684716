import React from 'react';
import withIcon from '../withIcon';

function Cart() {
  return (
    <path d="M20.162 3.698v7.124L6.34 12.72 4.753 3.698h15.41zm-4.457 16.368a1.059 1.059 0 1 1 .002-2.115 1.059 1.059 0 0 1-.002 2.115zm-7.588 0a1.06 1.06 0 0 1 0-2.116 1.059 1.059 0 0 1 0 2.116zM20.954 2.042H4.424L4.1.632c-.095-.4-.428-.662-.775-.628L.782 0C.351 0 0 .37 0 .828c0 .457.35.828.782.828h1.926l.32 1.378 1.85 10.511L4.2 15.41l-.516.005a.846.846 0 0 0 .008 1.692H3.7l2.455-.022a2.739 2.739 0 0 0-.788 1.923 2.753 2.753 0 0 0 2.75 2.751 2.753 2.753 0 0 0 2.75-2.75 2.74 2.74 0 0 0-.824-1.959l3.776-.033a2.737 2.737 0 0 0-.864 1.991 2.753 2.753 0 0 0 2.75 2.751 2.753 2.753 0 0 0 2.75-2.75c0-.804-.353-1.521-.904-2.025l2.766-.024a.846.846 0 0 0-.007-1.693h-.008l-14.4.127.367-.995 14.788-2.029c.392-.054.687-.407.687-.82v-8.68c0-.457-.355-.827-.79-.827z" />
  );
}

export default withIcon(Cart);
