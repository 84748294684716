import React from 'react';
import withIcon from '../withIcon';

function QuestionMedium() {
  return (
    <path d="M9.088 7.56c.165-.155.348-.301.548-.44a3.989 3.989 0 0 1 2.316-.712c.421 0 .812.061 1.172.184.36.123.67.296.932.52.261.224.467.496.616.816.15.32.224.68.224 1.08 0 .405-.06.755-.18 1.048-.12.293-.272.55-.456.772a3.51 3.51 0 0 1-.604.576c-.219.163-.423.316-.612.46-.19.144-.352.288-.488.432a.836.836 0 0 0-.236.48L12.176 14H11.2l-.096-1.328v-.088c0-.224.06-.423.18-.596s.272-.336.456-.488c.184-.152.381-.304.592-.456.21-.152.408-.32.592-.504s.336-.393.456-.628.18-.512.18-.832a1.41 1.41 0 0 0-.504-1.1 1.627 1.627 0 0 0-.548-.3 2.15 2.15 0 0 0-.676-.104c-.325 0-.604.04-.836.12a3.135 3.135 0 0 0-.976.528c-.099.08-.18.12-.244.12a.348.348 0 0 1-.312-.184l-.376-.6zm1.568 9.56a1.017 1.017 0 0 1 .284-.712.979.979 0 0 1 .708-.296c.139 0 .27.027.392.08.123.053.23.125.32.216a1.017 1.017 0 0 1 .288.712.983.983 0 0 1-1 1c-.139 0-.27-.025-.392-.076a.929.929 0 0 1-.316-.212 1.017 1.017 0 0 1-.284-.712zM12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0 1C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
  );
}

export default withIcon(QuestionMedium);
