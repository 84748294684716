/* App Routes */
import Tennis from "routes/Tennis/Tennis.component";
import Account from "routes/Account/Account.component";

export const appRoutes = {
  home: {
    exact: true,
    path: "/",
    component: Tennis,
  },
  account: {
    path: "/account",
    component: Account,
  },
};
